import React  from 'react';
import './samt.css';
import sdmt from './../img/sdmt.png';
import soin from './../img/soin.png';
 
import smuk from './../img/smuk.png';
import sbook from './../img/sbook.jpg';
 
/* import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css'; */
 
    import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
        FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
         FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
          FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
          FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
           FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers, FaAtom, FaLongArrowAltRight, FaFlag} from 'react-icons/fa';
                  
     




    function Samt() {
       

return(
    <div className="samt">
  <div className='samt_block'>
    <div className='block_1'>
    <div className="icon_block">
    <img 
           
           src={sdmt}
           alt="Низомномаи кафедра"
           width="100%"
           height="100%"
           
           />
 </div> 
<div className='text_block'><a className="s_title" href="/s_dmt">Суруди ДМТ</a></div> 
</div>
    <div className='block_2'>
    <div className="icon_block">
    <img 
           
           src={soin}
           alt="Низомномаи кафедра"
           width="100%"
           height="100%"
           
           /></div>
<div className='text_block'><a className="s_title">Низомномаи кафедра</a></div> 
    </div>
    <div className='block_3'>
    <div className="icon_block"> <img 
           
           src={smuk}
           alt="Низомномаи кафедра"
           width="100%"
           height="100%"
           
           />
 </div>
<div className='text_block'><a className="s_title" href="/m_mili">Муқаддасоти миллӣ</a></div> 
    </div>
    <div className='block_4'>
    <div className="icon_block">
    <img 
           
           src={sbook}
           alt="Низомномаи кафедра"
           width="100%"
           height="100%"
           
           />
 </div>
<div className='text_block'><a className="s_title" href="/k_pr">Китобҳои президент</a></div> 
    </div>
  </div>
      
</div> 
    
    )
  }  export default Samt;