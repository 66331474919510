import React from "react";
import './Java.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import { useState } from 'react'
import DG from './../img/DG.jpeg';
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaJs, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaJava} from 'react-icons/fa';

  

 
  function Java() {
      return (
        <div className="container">
            <div className="js_top">
             
              <div className="ico_title"><h2>Java<FaJava className="java"/></h2> 
              <ul>
                <li>Таҳиягари забон - <b>Ҷеймс Гослинг</b> ва ширкати <b>Sun Microsystems </b> </li>
                <li>Коркарди забон - соли 1995</li>
              </ul>
              </div> 
              <div className="img_title">
                <img 
           src={DG}
           alt="DG"
           width="70px"
           height="80px"
           className="javaimg"
                      /> </div> 
                       </div>
            
       <div className="js_bottom">

 <p>  <b>Java</b> забони барномасозии дар шаклгузорӣ ҷиддӣ, ба объект нигаронидашуда ва дар истифода умумӣ ба ҳисоб меравад.</p>

 <p>Рушди забон тавассути раванди ҷомеаи Java Community Process ташкил карда шудааст. Забон ва технологияҳои асосие, ки онро амалӣ мекунанд, 
    таҳти иҷозатномаи GPL паҳн карда мешаванд. Ҳуқуқҳои тамғаи молӣ ба Oracle Corporation тааллуқ доранд. </p>
    <p>  Бо шарофати бехатарӣ, ки дар сатҳи забон амалӣ карда шудааст, Java барои таҳияи системаҳои бехатари 
    бонкӣ, инчунин барои     таҳияи барномаҳо барои тиҷорат дар бозорҳои молиявӣ фаъолона истифода мешавад.</p>

    
  <p><b>Лоиҳаҳо: </b> </p>
    <ul className="loiha">
      <li>TheMarsRower,</li>
      <li>Amazon,</li>
      <li>Android,</li>
      <li>Google,</li>
      <li>eBay,</li>
      <li>Minecraft,</li>
      <li>Netflix,</li>
      <li>Uber,</li>
      <li>Eclipse IDE,</li>
      <li>Twiter,</li>
      <li>Spotify,</li>
      <li>CashApp,</li>
      <li>NasaWorldWind</li>
     

    </ul>
 
<a className="viki"> ВикипедиЯ </a>
       </div>
        

        </div>
   )
}

export default Java;