import axios from 'axios';  
import './login.css';

import { NavLink } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react'  
import {AiOutlineSwapRight} from 'react-icons/ai';

export const Login=()=>{
    let navigate = useNavigate();

    const [user,setUser]=useState({email:'',pass:''})

    const handleChange=(e)=>{
        setUser({...user, [e.target.name]: e.target.value}); 
    }


    const submitForm=(e)=>{
        e.preventDefault(); 
       const sendData = {        
            email:user.email,
            pass:user.pass
        }

       // console.log(sendData);

        axios.post('https://dmttii.tj/api/login.php',sendData)
        
        .then((result)=>{
           
            if (result.data.Status === "200") { 
                window.localStorage.setItem('email', result.data.email);
                window.localStorage.setItem('userName', (result.data.names));  
                navigate(`/Update`);
            }
        else  {
                      alert('Истифодабаранда муайян нашуд..'); 
        }
      })  
    }


    return(
        <div className='LoginBackground'>
            
        <form onSubmit={submitForm} className='LoginContainer'>
       
        <div className="main-box">
        <div className="row">
        <div className='titleCloseBtnLogin'>
            <h5>Аслиятшиносӣ</h5>      <button className='bx'> <NavLink   href="/"  > ×</NavLink></button>
       </div>
        </div>
        <div className='mb-3'>
            <label htmlFor='email'><strong>Email</strong></label>
            <input type="email" name="email"  
           onChange={handleChange} 
           value={user.email}
           className='form-control rounded-0'
           />
        </div>
        <div className='mb-3'>
            <label htmlFor='password'><strong>Password</strong></label>
            <input type="password" 
            name="pass"
            onChange={handleChange} 
            value={user.pass}
            className='form-control rounded-0'
            />
        </div>
        <p><a href="">Сиркалима фаромӯш шуд   </a></p>
        <button type="submit"   className='btn btn-success w-100 rounded-0' >Воридшавӣ<AiOutlineSwapRight className='fai'/></button>



        </div>
        </form>
        </div>
    )
}

 