import React, {useState, useEffect} from 'react';
import './barnomasozi.css';
import JS from './JS';
import Java from './Java';
import bio from './../img/bio.png';
import Djobs from './../img/Djobs.jpg';
 
/* import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css'; */
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
    FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaLongArrowAltRight, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaCheckDouble, FaCheckSquare, FaCheck} from 'react-icons/fa';

    




    function Barnomasozi() {
      let msg="";
 /*const [checked1, setChecked1] = useState(true);
 const [checked2, setChecked2] = useState(true);
    

  
 
   if(checked1){       
      msg =  < JS/>
   } 
    
   if(checked2){
       msg =  < Java/>
   } */

   const [isCheckedTea, setIsCheckedTea] = useState(true);
   const [isCheckedCoffee, setIsCheckedCoffee] = useState(false);
 
   const handleChangeTea = () => {
      setIsCheckedTea(!isCheckedTea);

      if (isCheckedCoffee) {
        setIsCheckedCoffee(!isCheckedCoffee); 
      }
     
    }
    
   const handleChangeCoffee = () => {
        setIsCheckedCoffee(!isCheckedCoffee);
        if (isCheckedTea) {
          setIsCheckedTea(!isCheckedTea); 
        }
       
      }

      if(isCheckedTea){       
            msg =  < JS/>
         } 
    
         if(isCheckedCoffee){       
            msg =  < Java/>
         } 


return(
    <div className="item_8" data-aos="fade-zoom-in">
  
      
      <div className='section_81'>
    
           
      <div className='item_81'> 
      <h1>Барномасозӣ</h1>
      <div className='text_81'>
 <p>Барномасозӣ - ин раванди эҷод ва тағйир додани барномаҳои компютерӣ.</p>
 <ul className='sd'>
 <li className='Djobs'><img 
           src={Djobs}
           alt="stiv"
           width="100%"
           height="100%"
                      /></li>
      <li className='pleft'><p>Барномасозӣ ин худ санъат аст, санъати фикрронӣ ва эҷодкунӣ. 
            Пас ҳар як нафар бояд ба омӯзиши барномасозӣ машғул гардад, чунки ин ба ӯ  фикррониро меомӯзад.</p>
             <a>Стив Джобс </a>
            </li>
      
 </ul>
 
      </div>
      </div>
      <div className='item_82'>
 
      <div className='text_82'>
            <div className='left_82'> Frontend </div>
            <div className='center_82'>Backend</div>
            <div className='right_82'><FaLongArrowAltRight className="FaReg"/> Full Stack</div>
      </div>
             </div>
       
      <div className='item_83'> 
      <div className='text_830'>
            <ul>
                  <li><p><a className='az'>Frontend</a> ин қисми ба чашм айёни барнома ё маҳсули ададӣ буда, 
                  <a className='az'> Backend</a> қисми барномавию таҷҳизотии он мебошад ва он аз чашми корбар 
                        (ё истифодабаранда) пинҳон аст. 
Дар <a className='az'>Frontend</a> коде навишта мешавад, ки дар браузер кор мекунад ва танҳо ин код чизеро, ки 
 дар ниҳоят истифодабаранда хоҳад дид, муайян месозад.

</p></li>
                  <li><p><a className='az'>Backend </a>ҳам худ коди навишташуда буда, 
                        барои сервер, ки дар он ҷо дархостҳои истифодабарандагон коркард мегарданд, муқаррар гаштааст.</p></li>
                  <li><p>
Дар соҳаи барномасозӣ “сарбозони универсалӣ” – <a className='az'>Full Stack </a>таҳиягарон дида мешаванд, ки онҳо ҳам маҳорати 
бо <a className='az'>Frontend</a> ва ҳам бо <a className='az'>Backend</a> кор карданро доранд.
</p></li>
            </ul>
            


      </div>
      
      <div className='text_84'>
            
             <div className='zb_l'>
<div className='zb_grid'>
      
      <div className='item JavaScript'>
      <input
          type="checkbox"
          checked={isCheckedTea}
          onChange={handleChangeTea }
        />

    {/*  <input type="checkbox" checked={checked1} onChange={  () =>   setChecked1(!checked1)} />*/}


       <a>JavaScript</a></div>   
            
              
      <div className='item Java'>

    {/*  <input type="checkbox" checked={checked2} onChange={() => setChecked2(!checked2)} />*/}
    <input
          type="checkbox"
          checked={isCheckedCoffee}
          onChange={handleChangeCoffee}
        />

      <a>Java</a></div>


      <div className='item C'><input  type="checkbox"/><a>C</a></div>
      <div className='item C++'><input  type="checkbox"/><a>C++</a></div>
      <div className='item C#'><input  type="checkbox"/><a>C#</a></div>

      <div className='item Python'><input  type="checkbox"/><a>Python</a></div>
      <div className='item Pascal'><input  type="checkbox"/><a>Pascal</a></div>
      <div className='item PHP'><input  type="checkbox"/><a>PHP</a></div>
      <div className='item Go'><input  type="checkbox"/><a>Go</a></div>
      <div className='item SQL'><input  type="checkbox"/><a>SQL</a></div>
      
      <div className='item HTML'><input  type="checkbox"/><a>HTML</a></div>
      <div className='item Delphi'><input  type="checkbox"/><a>Delphi</a></div>
      <div className='item NodeJS'><input  type="checkbox"/><a>NodeJS</a></div>
      <div className='item Assembler'><input  type="checkbox"/><a>Assembler</a></div>
      <div className='item Fortran'><input  type="checkbox"/><a>Fortran</a></div>
      <div className='item Kotlin'><input  type="checkbox"/><a>Kotlin</a></div>
      <div className='item Ada'><input  type="checkbox"/><a>Ada</a></div>
      <div className='item TypeScript'><input  type="checkbox"/><a>TypeScript</a></div>
      <div className='item Ruby'><input  type="checkbox"/><a>Ruby</a></div>
      <div className='item VisualBasic'><input  type="checkbox"/><a>VisualBasic</a></div>
      <div className='item Swift'><input  type="checkbox"/><a>Swift</a></div>
      <div className='item CSS'><input  type="checkbox"/><a>CSS</a></div>
      <div className='item JScript'><input  type="checkbox"/><a>JScript</a></div>
      <div className='item Basic'><input  type="checkbox"/><a>Basic</a></div>
      <div className='item Refal'><input  type="checkbox"/><a>Рефал</a></div>
</div>

</div>
<div className='zb_r'>
 {msg} </div>  
            </div>           <div className='tiob'><span>Машҳурияти забонҳои барномасозӣ дар <a href="#tiobe">TIOBE Index</a></span></div> 
      </div>  
     
      </div> 
      
</div> 
    
    )
  }  export default Barnomasozi;