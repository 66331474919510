 
import './mavodho.css';
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BrowserRouter as Router, Switch, Route, Link}  from 'react-router-dom';
/* import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css'; */
 
    import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
        FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
         FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
          FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
          FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
           FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers, FaAtom, FaLongArrowAltRight} from 'react-icons/fa';
                  
    
    function Mavodho() {
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);


        const [show_h, setShow_h] = useState(false);
        const handleClose_h = () => setShow_h(false);
        const handleShow_h = () => setShow_h(true);




return(
  
    <div className="mavodho">
 


<div className="item_7" data-aos="fade-zoom-in"> 
 
 <div className="h_item7">Маводҳо </div>
 
   
 
<div className="section_7">
<div className="s1" >
 <div className="line7"></div>
 <div className="title7"> <a href="/mavodho" className="aleft">Васоити таълимӣ</a> </div>
 
  
 <div className="text7">Васоити таълимии кафедра 
 аз тарафи омӯзгорони кафедра таҳия шуда...</div>
</div>

<div className="s2">
<div className="line7"></div>
 <div className="title7">Тестҳои санҷишӣ</div>
 <div className="text7">Тестҳои фаннӣ 
 аз тарафи омӯзгорони кафедра таҳия шуда...</div>
</div>
<div className="s3">
 

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Мавзуи кори курсӣ</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <table>
   <tr >
       <td className='ttd'> </td>
       <td  className='ttd'></td>
       <td  className='ttd'><b>Ихтисоси 98010101 курси 4</b> </td>
       <td  className='ttd'> </td>
     </tr>
     <tr className='tras'>
       <td >№</td>
       <td  >Номи насаби донишҷӯ</td>
       <td  >Мавзуъ</td>
       <td  >Роҳбар</td>
     </tr>
     <tr>
       <td>1</td>
       <td>Нуриддинов Ш.</td>
       <td>Таснифи рамзгузориҳо</td>
       <td>дотс. Рустамова Х.</td>
     </tr>
     <tr>
       <td>2</td>
       <td>Ахмедов Ю.</td>
       <td>Технологияи мобилӣ</td>
       <td>дотс. Рустамова Х.</td>
     </tr>
     <tr>
       <td>3</td>
       <td>Ҳусейнов С.</td>
       <td>Ҳифз аз дастрасии беиҷозат</td>
       <td>дотс. Рустамова Х.</td>
     </tr>
     <tr>
       <td>4</td>
       <td>Асилзода С.</td>
       <td>Таҳияи маъломотномаи  ҳуҷҷатнигорӣ </td>
       <td>дотс. Рустамова Х.</td>
     </tr>
     <tr>
       <td>5</td>
       <td>Мирзомуродов Б.</td>
       <td>Равандҳои ҳифзу коркарди иттилоот</td>
       <td>проф. Нуров И.</td>
     </tr>
     <tr>
       <td>6</td>
       <td>Ҷононов Ш.</td>
       <td>Татбиқи имзои электронӣ дар ҳалли масъалаҳои иқтисодӣ</td>
       <td>проф. Нуров И.</td>
     </tr>
     <tr>
       <td>7</td>
       <td>Бердиев Д.</td>
       <td>Истифодаи технологияи иттилоотӣ ва иртиботӣ барои муайянкунии баҳои иқтисодии заминҳои гуногуни кишоварзӣ</td>
       <td>дотс. Қурбонов К.Ю.</td>
     </tr>
     <tr>
       <td>8</td>
       <td>Худойбердиев И.</td>
       <td>Таҳияи барномаҳои рӯимизӣ</td>
       <td>дотс. Ҷумаев Э.Ҳ.</td>
     </tr>
     <tr>
       <td>9</td>
       <td>Гадоев А.</td>
       <td>Истифодаи методҳои криптографӣ барои ҳифзи системаҳои иттилоотӣ</td>
       <td>дотс. Ашӯров Х.</td>
     </tr>
     <tr>
       <td>10</td>
       <td>Ғуломов М.</td>
       <td>Системаҳои идоракунии хазинаи маълумот</td>
       <td>дотс. Ашӯров Х.</td>
     </tr>
     <tr>
       <td>11</td>
       <td>Миралиев И.</td>
       <td>Методҳои қадимаи рамзгузорӣ ва барномарезии онҳо дар муҳити Visual Studio </td>
       <td>м.к. Пиров С.М.</td>
     </tr>
     <tr>
       <td>12</td>
       <td>Мирзоев А.</td>
       <td>Истифодаи методҳои муосири рамзгузории иттилоот ва татбиқи онҳо</td>
       <td>м.к. Пиров С.М.</td>
     </tr>
     <tr>
       <td>13</td>
       <td>Ғозиев М.</td>
       <td>Протоколҳои ҳифзи иттилоот ва истифодабарии онҳо</td>
       <td>м.к. Пиров С.М.</td>
     </tr>
     <tr>
       <td>14</td>
       <td>Рабизода  Ш.</td>
       <td>Кибертерроризм падидаи номатлуб</td>
       <td>м.к. Комилов О.</td>
     </tr>
     <tr>
       <td>15</td>
       <td>Сатторов А.</td>
       <td>Кодгузориҳо ва истифодаи васеи онҳо</td>
       <td>м.к. Комилов О.</td>
     </tr>
     <tr>
       <td>16</td>
       <td>Раҳимов Д.</td>
       <td>Роҳҳои ҳифзи иттилоот</td>
       <td>м.к. Комилов О.</td>
     </tr>
     <tr>
       <td>17</td>
       <td>Талбонзода М.</td>
       <td>Коркарди дархостҳо дар интернет-мағоза</td>
       <td>м.к. Ҷумаева Н.Э.</td>
     </tr>
     <tr>
       <td>18</td>
       <td>Қурбонов Э.</td>
       <td>Таҳия ва амалигардонии системаи иттилоотии “Идрор” барои мактаби олӣ</td>
       <td>м.к. Ҷумаева Н.Э.</td>
     </tr>
     <tr>
       <td>19</td>
       <td>Латипов А.</td>
       <td>Забони барномасозии С# ва имкониятҳои он</td>
       <td>асс. Қурбонова Н.</td>
     </tr>
     <tr>
       <td>20</td>
       <td>Имомзода М.</td>
       <td>Ҳеш-функсияҳои криптографӣ</td>
       <td>м.к. Шарифзода З.И.</td>
     </tr>
     <tr>
       <td>21</td>
       <td>Зиёзода Д.</td>
       <td>Маҳдудиятҳо дар SQL</td>
       <td>м.к. Шарифзода З.И.</td>
     </tr>
     <tr>
       <td>22</td>
       <td>Ярова Р.</td>
       <td>Татбиқи усулҳои рамзгузории классикӣ</td>
       <td>м.к. Шарифзода З.И.</td>
     </tr>
     <tr>
       <td>23</td>
       <td>Каримов И.</td>
       <td>Рамзҳои классикии алгебравӣ</td>
       <td>асс. Мусоев С. </td>
     </tr>
   </table>
              
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
<div className="line7"></div>
 <div className="title7">  <a onClick={handleShow}>Мавзуи кори курсӣ</a>
  
 </div>
 
 <div className="text7">Мавзуҳои корҳои курсӣ аз тарафи кафедра пешниҳод мегардад..</div>
</div>
<div className="s4">

<Modal show={show_h} onHide={handleClose_h}>
        <Modal.Header closeButton>
          <Modal.Title>Мавзуи кори хатм</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <table>
<tr >
    <td className='ttd'> </td>
    <td  className='ttd'></td>
    <td  className='ttd'><b>Ихтисоси 98010101</b> </td>
    <td className='ttd' > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Асилзода Саидвазир Нозим</td>
    <td>Коркарди маълумоти корманд дар барномаи MS Excel</td>
    <td>н.и.и., дотсент Рустамова Х.Р.</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Ахмедов Юсуфҷон Саидович</td>
    <td>Ҳифзи додаҳои мобилӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>3</td>
    <td>Бердиев Дилшод Ҳамзаевич</td>
    <td>Истифодаи технологияи иттилоотӣ ва иртиботӣ барои муайянкуни намудани меъёрҳои мустақилияти озуқавории маҳсулотҳо</td>
    <td>н.и.и., дотсент Қурбонов К.Ю.</td>
  </tr>
  <tr>
    <td>4</td>
    <td>Гадоев Аҳлиддин Шуҳратович</td>
    <td>Стандарти кодгузории символҳои Unicode</td>
    <td>н.и.и., дотсент Ашуров Х.М.</td>
  </tr>
  <tr>
    <td>5</td>
    <td>Ғозиев Муҳаммадсодиқ Маҳмадшариф</td>
    <td> Истифодаи методҳои муосири рамзгузории иттилоот</td>
    <td>м.к. Пиров С.М.</td>
  </tr>
  <tr>
    <td>6</td>
    <td>Ғуломов Меҳрубон Турсунмуродович</td>
    <td>Масъалаҳои назариявии ҳифзи иттилоот</td>
    <td>н.и.и., дотсент Ашуров Х.М.</td>
  </tr>
  <tr>
    <td>7</td>
    <td>Зиёзода Додихудои Маҳмадшариф</td>
    <td>Татбиқи усулҳои криптографӣ дар пойгоҳи додаҳо</td>
    <td>н.и.ф.м., м.к. Шарифзода З.И.</td>
  </tr>
  <tr>
    <td>8</td>
    <td>Имомзода Меҳриддин Насриддин </td>
    <td>Пулҳои электронӣ-рақамӣ ва бехатарии он</td>
    <td>н.и.ф.м., м.к. Шарифзода З.И.</td>
  </tr>
  <tr>
    <td>9</td>
    <td>Каримов Исмоил  Ҳикматович</td>
    <td>Рамзи классики Шамир дар забони Python</td>
    <td>асс. Мусоев С.С.
</td>
  </tr>
  <tr>
    <td>10</td>
    <td>Қурбонов Эрач Эмомалиевич</td>
    <td>Сохтани маълумотномаи электронӣ оид ба таснифи рамзҳо</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>11</td>
    <td>Латипов Афзалшоҳ Амирбегович</td>
    <td>Истифодаи технологияи иттилоотӣ ва иртиботӣ барои муайянкунии гурӯҳбандии нишондиҳандаҳои асосии соҳаҳо</td>
    <td>н.и.и., дотсент Қурбонов К.Ю.</td>
  </tr>
  <tr>
    <td>12</td>
    <td>Миралиев Исмоил Хайрулоевич</td>
    <td>Таҳияи барномаи рамзгузории иттилоот дар муҳити Visual Studio бо истифодаи рамзҳои қадима</td>
    <td>м.к. Пиров С.М.</td>
  </tr>
  <tr>
    <td>13</td>
    <td>Мирзоев Абдулатиф Абдулазизович</td>
    <td>Протоколҳои ҳифзи иттилоот ва истифодабарии онҳо дар мисолҳои мушаххас</td>
    <td>м.к. Пиров С.М.</td>
  </tr>
  <tr>
    <td>14</td>
    <td>Мирзомуродов Бобоҷон</td>
    <td>Усулҳои ҳифзу коркарди иттилоот  бо истифода аз протоколҳои криптографӣ</td>
    <td>д.и.ф.-м., проф.Нуров И.Ҷ.</td>
  </tr>
  <tr>
    <td>15</td>
    <td>Нуриддинов Шаҳром Сулҳиддинович</td>
    <td>Рамзгузорӣ бо усули алгебравӣ</td>
    <td>н.и.и., дотсент Рустамова Х.Р.</td>
  </tr>
  <tr>
    <td>16</td>
    <td>Рабизода Шерзоди Мурод</td>
    <td>Киберҳамлаҳо ва ҳифз аз онҳо</td>
    <td>н.и.ф.-м., дотс.Комилов О.О.</td>
  </tr>
  <tr>
    <td>17</td>
    <td>Раҳимов Диловар Мирзоҷумъаевич</td>
    <td>Кодгузории ASCII дар амалия</td>
    <td>н.и.ф.-м., дотс.Комилов О.О.</td>
  </tr>
  <tr>
    <td>18</td>
    <td>Сатторов Алиҷон Самаридинович </td>
    <td>Таҳияи Web- сомонаи олимони барҷастаи форсу тоҷик</td>
    <td>н.и.ф.-м., дотс.Комилов О.О.</td>
  </tr>
  <tr>
    <td>19</td>
    <td>Талбонзода Мардихудои Муҳамади</td>
    <td>Татбиқи рамзи Афинӣ дар забони NODEJS</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  
  <tr>
    <td>20</td>
    <td>Худойбердиев Исмоилчон </td>
    <td>Таҳияи системаи бақайдгирии қабул ва фуруши маводи доруворӣ барои дорухона</td>
    <td>н.и.ф.-м., дотс.Ҷумаев Э.Ҳ.</td>
  </tr>
  <tr>
    <td>21</td>
    <td>Ҳусейнов Сино Саъдиевич </td>
    <td>Ҳифзи додаҳо дар муассиса</td>
    <td>н.и.и., дотс.Рустамова Х.Р.</td>
  </tr>
  <tr>
    <td>22</td>
    <td>Ҷононов Шерзод  Ҷалолович </td>
    <td>Забони барномасозии Python  дар татбиқи имзои электронӣ</td>
    <td>д.и.ф.-м., проф. Нуров И.Ҷ.</td>
  </tr>
  <tr>
    <td>23</td>
    <td>Ярова Раъно Юсуфалиевич </td>
    <td>Таҳияи маҷмӯаи барномаҳо доир ба усулҳои классикии рамзгузорӣ</td>
    <td>н.и.ф.м., м.к. Шарифзода З.И.</td>
  </tr>
  
  <tr  >
    <td className='ttd'> </td>
    <td  className='ttd'></td>
    <td className='ttd' > <b>Ихтисоси 21050103</b> </td>
    <td className='ttd' > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr className='ttd'>
    <td className='ttd'> </td>
    <td  className='ttd'> </td>
    <td className='ttd' > <b>Ихтисоси 02030702</b></td>
    <td className='ttd' > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Ғоибзода С.</td>
    <td>Таҳлили сохтории истилоҳоти мансуби "роҳбарият, маъмурият ва биноҳои муассисаҳои таълимӣ" дар забонҳои тоҷикию чинӣ ва таҳияяи фарҳанги электронии он.</td>
    <td>Асозода Ҳ., Рустамова Х.Р.
</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Бурҳонов Б.</td>
    <td>Таркиби морфологии зарфи тарзи  амал дар забонҳои тоҷикию чинӣ ва таҳияи барномаи компютерии он.</td>
    <td>Маҳмадов Ҷ., Пиров Саидалӣ </td>
  </tr>
  <tr>
    <td>3</td>
    <td>Ёров И.</td>
    <td>Нақши неологизмҳо дар забони тоҷикӣ ва роҳҳои истифодаи он дар забони чинӣ.</td>
    <td>Маҳмадов Ҷ., Пиров Саидалӣ</td>
  </tr>
  <tr>
    <td>4</td>
    <td>Мирзошарифзода С.</td>
    <td>Таҳлили лексикию семантикии истилоҳи хешутаборӣ дар забонҳои гуногунсохтор (дар асоси маводи забонҳои тоҷикию чинӣ) ва фарҳангӣ электронии он.</td>
    <td>Ибрагимов А., Қурбонов K.</td>
  </tr>
  <tr>
    <td>5</td>
    <td>Боймуродов Ш.</td>
    <td>Шаклҳои тасрифшавандаи  феъл дар забонҳои тоҷикӣ ва чинӣ, барномарезии он дар муҳити VS C#</td>
    <td>Қаҳорзода А., Комилов О. </td>
  </tr>
  <tr>
    <td>6</td>
    <td>Усмонова Н.</td>
    <td>Мавқеи истифодаи пайвандакҳои аммо дар ҷумлаи пайравии пуркунандаи забонҳои тоҷикию чинӣ ва коркарди компютерии он.</td>
    <td>Ғиёсзода С.,Рустамова X.
</td>
  </tr>
  <tr>
    <td>7</td>
    <td>Раҷабов Х.</td>
    <td>Мавқеи ҷумлаҳои унвонӣ  дар забонҳои  тоҷикӣ ва чинӣ. Сохтани маълумотномаи электронӣ.</td>
    <td>Маҳмадов Ҷ., Комилов О.</td>
  </tr>
  <tr>
    <td>8</td>
    <td>Замонов М.</td>
    <td>Нақши зарбулмасалу мақолҳо дар шаклгирии ҷумлаҳои яктаркиба дар забонҳои тоҷикӣ  ва чинӣ. Таҳияи  Web-саҳифа</td>
    <td>Маҳмадов Ҷ.,Шарифзода З. 
</td>
  </tr>
  <tr>
    <td>9</td>
    <td>Мавҷудшоева С.</td>
    <td>Корбурди воситаҳои асосии алоқаи ҷумлаи пайрави пуркунанда бо сарҷумла дар забонҳои
       тоҷикӣ  ва роҳҳои ифодаи онҳо дар забони чинӣ. Таҳияи барномаи компютерӣ.</td>
    <td>Асозода Ҳ.,Шарифзода З.
</td>
  </tr>
  <tr>
    <td>10</td>
    <td>Ҷобирзода Т.</td>
    <td>Хусусиятҳои функсионалӣ ва услубии ҷумлаҳои пайрав дар забонҳои тоҷикӣ ва чинӣ, барномарезӣ дар муҳити 
Delphi 2010
</td>
    <td>Назиров Ш.,Комилов О.
.</td>
  </tr>
  <tr>
    <td>11</td>
    <td>Гадоев М.</td>
    <td>Масъалаи омӯзиши мавқеи хусусиятҳои семантикии 
      воҳидҳои фразеологии соматикӣ дар забонҳои тоҷикиву чинӣ (бо ҷузъҳои даст, сар ва дил) ва барномарезии он.</td>
    <td>Ибрагимов А., Мусоев С. </td>
  </tr>

  <tr>
    <td>12</td>
    <td>Маҳмудзода Т.</td>
    <td>Таҳлили семантикии ибораҳои феълӣ  дар забонҳои  тоҷикиву чинӣ ва таҳияи луғати бехат (offline)</td>
    <td>Назиров Ш., Рустамова Х.
 </td>
  </tr>


  <tr>
    <td>13</td>
    <td>Нурматов З.</td>
    <td>Таҳлили лексикию семантикии воҳидҳои забон дар системаи 
       истилоҳоти компютерӣ дар забонҳои тоҷикию чинӣ ва таҳияи луғати электронии бархати  он.</td>
    <td>Ғиёсзода С., Пиров С. </td>
  </tr>


  <tr>
    <td>14</td>
    <td>Муҳиддинов А.</td>
    <td>Таҳлили семантикии консепсияи "осмон" дар забонҳои тоҷикию чинӣ, барномарезии он </td>
    <td>Ибрагимов А.,Ҷумаева Н.
 </td>
  </tr>

  <tr>
    <td>15</td>
    <td>Абдулбадров З.</td>
    <td>Тарзи сохторӣ ва грамматикии мафҳуми “сар” 
      дар воҳидҳои фразеологии забонҳои тоҷикиву чинӣ ва таҳияи барномаи он дар муҳити Embarcadero.</td>
    <td>Назиров Ш., Шодибекова М. </td>
  </tr>


  <tr>
    <td>16</td>
    <td>Ҳафизов А.</td>
    <td>Манбаъ ва маншаи иероглифҳо дар забони чинӣ  ва маълумотномаи он дар Web.</td>
    <td>Назиров Ш., Шодибекова М. </td>
  </tr>


   
  
</table>
              
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>



<div className="line7"></div>
 <div className="title7"><a onClick={handleShow_h}>Мавзуи кори хатм</a></div>
 <div className="text7">Мавзуи кори хат аз тарафи кафедра пешниҳод мегардад..</div>
</div>


</div>
</div>
 
</div>
      
 
    
    )
  }  export default Mavodho;