import React from 'react';
import './Modalkh.css';
function Modalkh({ closeModalkh }){
    return(
       <div className='modalBackgroundkh'> 
       <div className='modalContainerkh'> 
       <div className='titleCloseBtnkh'>
         <button  onClick={() => closeModalkh(false)}>×</button>
       </div>
      <p>Руйхати корҳои хатмкунӣ 2023-2024</p> 
       <div className='jdkk'> 
<table>
<tr className='ttd'>
    <td > </td>
    <td  ></td>
    <td  > Ихтисоси 98010101</td>
    <td  > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Асилзода Саидвазир Нозим</td>
    <td>Коркарди маълумоти корманд дар барномаи MS Excel</td>
    <td>н.и.и., дотсент Рустамова Х.Р.</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Ахмедов Юсуфҷон Саидович</td>
    <td>Ҳифзи додаҳои мобилӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>3</td>
    <td>Бердиев Дилшод Ҳамзаевич</td>
    <td>Истифодаи технологияи иттилоотӣ ва иртиботӣ барои муайянкуни намудани меъёрҳои мустақилияти озуқавории маҳсулотҳо</td>
    <td>н.и.и., дотсент Қурбонов К.Ю.</td>
  </tr>
  <tr>
    <td>4</td>
    <td>Гадоев Аҳлиддин Шуҳратович</td>
    <td>Стандарти кодгузории символҳои Unicode</td>
    <td>н.и.и., дотсент Ашуров Х.М.</td>
  </tr>
  <tr>
    <td>5</td>
    <td>Ғозиев Муҳаммадсодиқ Маҳмадшариф</td>
    <td> Истифодаи методҳои муосири рамзгузории иттилоот</td>
    <td>м.к. Пиров С.М.</td>
  </tr>
  <tr>
    <td>6</td>
    <td>Ғуломов Меҳрубон Турсунмуродович</td>
    <td>Масъалаҳои назариявии ҳифзи иттилоот</td>
    <td>н.и.и., дотсент Ашуров Х.М.</td>
  </tr>
  <tr>
    <td>7</td>
    <td>Зиёзода Додихудои Маҳмадшариф</td>
    <td>Татбиқи усулҳои криптографӣ дар пойгоҳи додаҳо</td>
    <td>н.и.ф.м., м.к. Шарифзода З.И.</td>
  </tr>
  <tr>
    <td>8</td>
    <td>Имомзода Меҳриддин Насриддин </td>
    <td>Пулҳои электронӣ-рақамӣ ва бехатарии он</td>
    <td>н.и.ф.м., м.к. Шарифзода З.И.</td>
  </tr>
  <tr>
    <td>9</td>
    <td>Каримов Исмоил  Ҳикматович</td>
    <td>Рамзи классики Шамир дар забони Python</td>
    <td>асс. Мусоев С.С.
</td>
  </tr>
  <tr>
    <td>10</td>
    <td>Қурбонов Эрач Эмомалиевич</td>
    <td>Сохтани маълумотномаи электронӣ оид ба таснифи рамзҳо</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>11</td>
    <td>Латипов Афзалшоҳ Амирбегович</td>
    <td>Истифодаи технологияи иттилоотӣ ва иртиботӣ барои муайянкунии гурӯҳбандии нишондиҳандаҳои асосии соҳаҳо</td>
    <td>н.и.и., дотсент Қурбонов К.Ю.</td>
  </tr>
  <tr>
    <td>12</td>
    <td>Миралиев Исмоил Хайрулоевич</td>
    <td>Таҳияи барномаи рамзгузории иттилоот дар муҳити Visual Studio бо истифодаи рамзҳои қадима</td>
    <td>м.к. Пиров С.М.</td>
  </tr>
  <tr>
    <td>13</td>
    <td>Мирзоев Абдулатиф Абдулазизович</td>
    <td>Протоколҳои ҳифзи иттилоот ва истифодабарии онҳо дар мисолҳои мушаххас</td>
    <td>м.к. Пиров С.М.</td>
  </tr>
  <tr>
    <td>14</td>
    <td>Мирзомуродов Бобоҷон</td>
    <td>Усулҳои ҳифзу коркарди иттилоот  бо истифода аз протоколҳои криптографӣ</td>
    <td>д.и.ф.-м., проф.Нуров И.Ҷ.</td>
  </tr>
  <tr>
    <td>15</td>
    <td>Нуриддинов Шаҳром Сулҳиддинович</td>
    <td>Рамзгузорӣ бо усули алгебравӣ</td>
    <td>н.и.и., дотсент Рустамова Х.Р.</td>
  </tr>
  <tr>
    <td>16</td>
    <td>Рабизода Шерзоди Мурод</td>
    <td>Киберҳамлаҳо ва ҳифз аз онҳо</td>
    <td>н.и.ф.-м., дотс.Комилов О.О.</td>
  </tr>
  <tr>
    <td>17</td>
    <td>Раҳимов Диловар Мирзоҷумъаевич</td>
    <td>Кодгузории ASCII дар амалия</td>
    <td>н.и.ф.-м., дотс.Комилов О.О.</td>
  </tr>
  <tr>
    <td>18</td>
    <td>Сатторов Алиҷон Самаридинович </td>
    <td>Таҳияи Web- сомонаи олимони барҷастаи форсу тоҷик</td>
    <td>н.и.ф.-м., дотс.Комилов О.О.</td>
  </tr>
  <tr>
    <td>19</td>
    <td>Талбонзода Мардихудои Муҳамади</td>
    <td>Татбиқи рамзи Афинӣ дар забони NODEJS</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  
  <tr>
    <td>20</td>
    <td>Худойбердиев Исмоилчон </td>
    <td>Таҳияи системаи бақайдгирии қабул ва фуруши маводи доруворӣ барои дорухона</td>
    <td>н.и.ф.-м., дотс.Ҷумаев Э.Ҳ.</td>
  </tr>
  <tr>
    <td>21</td>
    <td>Ҳусейнов Сино Саъдиевич </td>
    <td>Ҳифзи додаҳо дар муассиса</td>
    <td>н.и.и., дотс.Рустамова Х.Р.</td>
  </tr>
  <tr>
    <td>22</td>
    <td>Ҷононов Шерзод  Ҷалолович </td>
    <td>Забони барномасозии Python  дар татбиқи имзои электронӣ</td>
    <td>д.и.ф.-м., проф. Нуров И.Ҷ.</td>
  </tr>
  <tr>
    <td>23</td>
    <td>Ярова Раъно Юсуфалиевич </td>
    <td>Таҳияи маҷмӯаи барномаҳо доир ба усулҳои классикии рамзгузорӣ</td>
    <td>н.и.ф.м., м.к. Шарифзода З.И.</td>
  </tr>
  
  <tr className='ttd'>
    <td > </td>
    <td  >Ихтисоси 21050103 </td>
    <td  > </td>
    <td  > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr className='ttd'>
    <td > </td>
    <td  >Ихтисоси 02030702 </td>
    <td  > </td>
    <td  > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Ғоибзода С.</td>
    <td>Таҳлили сохтории истилоҳоти мансуби "роҳбарият, маъмурият ва биноҳои муассисаҳои таълимӣ" дар забонҳои тоҷикию чинӣ ва таҳияяи фарҳанги электронии он.</td>
    <td>Асозода Ҳ., Рустамова Х.Р.
</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Бурҳонов Б.</td>
    <td>Таркиби морфологии зарфи тарзи  амал дар забонҳои тоҷикию чинӣ ва таҳияи барномаи компютерии он.</td>
    <td>Маҳмадов Ҷ., Пиров Саидалӣ </td>
  </tr>
  <tr>
    <td>3</td>
    <td>Ёров И.</td>
    <td>Нақши неологизмҳо дар забони тоҷикӣ ва роҳҳои истифодаи он дар забони чинӣ.</td>
    <td>Маҳмадов Ҷ., Пиров Саидалӣ</td>
  </tr>
  <tr>
    <td>4</td>
    <td>Мирзошарифзода С.</td>
    <td>Таҳлили лексикию семантикии истилоҳи хешутаборӣ дар забонҳои гуногунсохтор (дар асоси маводи забонҳои тоҷикию чинӣ) ва фарҳангӣ электронии он.</td>
    <td>Ибрагимов А., Қурбонов K.</td>
  </tr>
  <tr>
    <td>5</td>
    <td>Боймуродов Ш.</td>
    <td>Шаклҳои тасрифшавандаи  феъл дар забонҳои тоҷикӣ ва чинӣ, барномарезии он дар муҳити VS C#</td>
    <td>Қаҳорзода А., Комилов О. </td>
  </tr>
  <tr>
    <td>6</td>
    <td>Усмонова Н.</td>
    <td>Мавқеи истифодаи пайвандакҳои аммо дар ҷумлаи пайравии пуркунандаи забонҳои тоҷикию чинӣ ва коркарди компютерии он.</td>
    <td>Ғиёсзода С.,Рустамова X.
</td>
  </tr>
  <tr>
    <td>7</td>
    <td>Раҷабов Х.</td>
    <td>Мавқеи ҷумлаҳои унвонӣ  дар забонҳои  тоҷикӣ ва чинӣ. Сохтани маълумотномаи электронӣ.</td>
    <td>Маҳмадов Ҷ., Комилов О.</td>
  </tr>
  <tr>
    <td>8</td>
    <td>Замонов М.</td>
    <td>Нақши зарбулмасалу мақолҳо дар шаклгирии ҷумлаҳои яктаркиба дар забонҳои тоҷикӣ  ва чинӣ. Таҳияи  Web-саҳифа</td>
    <td>Маҳмадов Ҷ.,Шарифзода З. 
</td>
  </tr>
  <tr>
    <td>9</td>
    <td>Мавҷудшоева С.</td>
    <td>Корбурди воситаҳои асосии алоқаи ҷумлаи пайрави пуркунанда бо сарҷумла дар забонҳои
       тоҷикӣ  ва роҳҳои ифодаи онҳо дар забони чинӣ. Таҳияи барномаи компютерӣ.</td>
    <td>Асозода Ҳ.,Шарифзода З.
</td>
  </tr>
  <tr>
    <td>10</td>
    <td>Ҷобирзода Т.</td>
    <td>Хусусиятҳои функсионалӣ ва услубии ҷумлаҳои пайрав дар забонҳои тоҷикӣ ва чинӣ, барномарезӣ дар муҳити 
Delphi 2010
</td>
    <td>Назиров Ш.,Комилов О.
.</td>
  </tr>
  <tr>
    <td>11</td>
    <td>Гадоев М.</td>
    <td>Масъалаи омӯзиши мавқеи хусусиятҳои семантикии 
      воҳидҳои фразеологии соматикӣ дар забонҳои тоҷикиву чинӣ (бо ҷузъҳои даст, сар ва дил) ва барномарезии он.</td>
    <td>Ибрагимов А., Мусоев С. </td>
  </tr>

  <tr>
    <td>12</td>
    <td>Маҳмудзода Т.</td>
    <td>Таҳлили семантикии ибораҳои феълӣ  дар забонҳои  тоҷикиву чинӣ ва таҳияи луғати бехат (offline)</td>
    <td>Назиров Ш., Рустамова Х.
 </td>
  </tr>


  <tr>
    <td>13</td>
    <td>Нурматов З.</td>
    <td>Таҳлили лексикию семантикии воҳидҳои забон дар системаи 
       истилоҳоти компютерӣ дар забонҳои тоҷикию чинӣ ва таҳияи луғати электронии бархати  он.</td>
    <td>Ғиёсзода С., Пиров С. </td>
  </tr>


  <tr>
    <td>14</td>
    <td>Муҳиддинов А.</td>
    <td>Таҳлили семантикии консепсияи "осмон" дар забонҳои тоҷикию чинӣ, барномарезии он </td>
    <td>Ибрагимов А.,Ҷумаева Н.
 </td>
  </tr>

  <tr>
    <td>15</td>
    <td>Абдулбадров З.</td>
    <td>Тарзи сохторӣ ва грамматикии мафҳуми “сар” 
      дар воҳидҳои фразеологии забонҳои тоҷикиву чинӣ ва таҳияи барномаи он дар муҳити Embarcadero.</td>
    <td>Назиров Ш., Шодибекова М. </td>
  </tr>


  <tr>
    <td>16</td>
    <td>Ҳафизов А.</td>
    <td>Манбаъ ва маншаи иероглифҳо дар забони чинӣ  ва маълумотномаи он дар Web.</td>
    <td>Назиров Ш., Шодибекова М. </td>
  </tr>


   
  
</table>

      </div>
        
       
       </div> 
       </div>
        

     
    )
}

export default Modalkh;