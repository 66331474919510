import './tiobe.css';
import React, { useState } from 'react'
import tiobe_index from './../img/tiobe_index.png';

function Tiobe() {
   
  
  return (
    <div className='tiobe'>
      <div className='b_tiobe'>
<div className='d_tiobe'>
  <div className='l_tiobe'>
      <div className='d_1'> <h1>Машҳурияти забонҳои барномасозӣ дар <a href='https://www.tiobe.com'>TIOBE Index</a></h1></div>
      
      <div className='d_2'><p><b>Индекси TIOBE </b>(TIOBE programming community index)  - индексе (реестр, руйхат), 
        ки машҳурияти забонҳои барномасозиро дар 
        асоси натиҷаҳои дархостҳои ҷустуҷӯӣ баҳогузорӣ ё арзёбӣ мекунад.</p></div>
       
      <div className='d_4'>
        <h1> Забони барномасозии сол</h1>
        <p>Ҳамасола (аз соли 2003 то ин ҷониб), аз тарафи муаллифони TIOBE забони барномасозии сол интихоб мегардад (Programming Language of the Year): </p>
       <div className='d_block'>
       <ul>
        <li>2022 C++</li>
        <li>2021 Python</li>
        <li>2020 Python</li>
        <li>2019 C</li>
        <li>2018 Python</li>
        <li>2017 C</li>
        <li>2016 Go</li>
        <li>2015 Java</li>
        <li>2014 JavaScript</li>
        <li>2013 Transact-SQL</li>
        <li>2012 Objective-C</li>
        <li>2011 Objective-C</li>
        <li>2010 Python</li>
        <li>2009 Go</li>
        <li>2008 C</li>
        <li>2007 Python</li>
        <li>2006 Ruby</li>
        <li>2005 Java</li>
        <li>2004 PHP</li>
        <li>2003 C++</li>        
       </ul>
       <div className='ul_img'>
        <h1>Баҳогузорӣ то моҳи ноябри соли 2023</h1>
        <img 
           src={tiobe_index}
           alt="tiobe_index"
           width="100%"
           height="100%"
                      /></div> 
       </div> 

       
       </div>
       
       
       </div>
       
       
       


        </div> 

      </div>
      
 
        </div>
  );
}

export default Tiobe;