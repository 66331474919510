import Nav from 'react-bootstrap/Nav';
import './Nav.css';
 
function NavDMT() {
  return (
    <div>
    
        <Nav className='dmt'>
          <div className='dmt_img'>
          
          </div>
          <div className='dmt_text'>
            <p class="cl1">Донишгоҳи миллии Тоҷикистон</p>
    
    <p class="cl2">Кафедраи технологияи иттилоотӣ ва иртиботӣ</p>
    <p class="cl3">Факултети механикаю математика</p></div>
 
        </Nav>
        </div>
  );
}

export default NavDMT;