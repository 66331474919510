import React from 'react';
import './ModalMail.css';
import './Modal.css';
import {Form, Button, Container, FormGroup, FormLabel, FormControl} from 'react-bootstrap'
function ModalMail({ closeModalMail }){
    return(
       <div className='modalmBackground'> 
       <div className='modalmContainer'> 
       <div className='titlemCloseBtn'>
         <h1 className="text-center">Тамос бо мо</h1> <button  onClick={() => closeModalMail(false)}>×</button>
       </div>
      <Container className='ContMail'>
       
        <Form>
          <Form.Group style={ {marginBottom:'15px'}} controlId="formBasicEmail">
            <Form.Label>         Суроғаи почтаи электронии шумо      </Form.Label>
            <Form.Control  style={ {fontSize:'13px', color:'rgb(121, 121, 121)'}} type="email" placeholder="namuna@mail.ru" />
             
             </Form.Group>

            <Form.Group style={ {marginBottom:'15px'}}  controlId='formBasicPassword'>
              <Form.Label>Матни нома</Form.Label>
              <Form.Control  as="textarea" rows="6"/>
            </Form.Group> 

            <Form.Group style={ {marginBottom:'15px'}}  controlId='formBasicCheckbox'>
              <Form.Check style={ {marginTop:'15px'}}  type="checkbox" label="Тафтишкунӣ" />
            </Form.Group> 
            <button variant="primary" type="submit">Ирсоли нома</button>



        </Form>


      </Container>
        
       
       </div> 
       </div>
        

     
    )
}

export default ModalMail;