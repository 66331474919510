import React from 'react';
import './Modal.css';
function Modal({ closeModal }){
    return(
       <div className='modalBackground'> 
       <div className='modalContainer'> 
       <div className='titleCloseBtnModal'>
       <button  onClick={() => closeModal(false)}>×</button>
       </div><p>Руйхати корҳои курсӣ 2023-2024</p> 
      <div className='jdkk'> 
<table>
<tr className='ttd'>
    <td > </td>
    <td  ></td>
    <td  >Ихтисоси 98010101 курси 4 </td>
    <td  > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Нуриддинов Ш.</td>
    <td>Таснифи рамзгузориҳо</td>
    <td>дотс. Рустамова Х.</td>
  </tr>
  <tr>
    <td>2</td>
    <td>Ахмедов Ю.</td>
    <td>Технологияи мобилӣ</td>
    <td>дотс. Рустамова Х.</td>
  </tr>
  <tr>
    <td>3</td>
    <td>Ҳусейнов С.</td>
    <td>Ҳифз аз дастрасии беиҷозат</td>
    <td>дотс. Рустамова Х.</td>
  </tr>
  <tr>
    <td>4</td>
    <td>Асилзода С.</td>
    <td>Таҳияи маъломотномаи  ҳуҷҷатнигорӣ </td>
    <td>дотс. Рустамова Х.</td>
  </tr>
  <tr>
    <td>5</td>
    <td>Мирзомуродов Б.</td>
    <td>Равандҳои ҳифзу коркарди иттилоот</td>
    <td>проф. Нуров И.</td>
  </tr>
  <tr>
    <td>6</td>
    <td>Ҷононов Ш.</td>
    <td>Татбиқи имзои электронӣ дар ҳалли масъалаҳои иқтисодӣ</td>
    <td>проф. Нуров И.</td>
  </tr>
  <tr>
    <td>7</td>
    <td>Бердиев Д.</td>
    <td>Истифодаи технологияи иттилоотӣ ва иртиботӣ барои муайянкунии баҳои иқтисодии заминҳои гуногуни кишоварзӣ</td>
    <td>дотс. Қурбонов К.Ю.</td>
  </tr>
  <tr>
    <td>8</td>
    <td>Худойбердиев И.</td>
    <td>Таҳияи барномаҳои рӯимизӣ</td>
    <td>дотс. Ҷумаев Э.Ҳ.</td>
  </tr>
  <tr>
    <td>9</td>
    <td>Гадоев А.</td>
    <td>Истифодаи методҳои криптографӣ барои ҳифзи системаҳои иттилоотӣ</td>
    <td>дотс. Ашӯров Х.</td>
  </tr>
  <tr>
    <td>10</td>
    <td>Ғуломов М.</td>
    <td>Системаҳои идоракунии хазинаи маълумот</td>
    <td>дотс. Ашӯров Х.</td>
  </tr>
  <tr>
    <td>11</td>
    <td>Миралиев И.</td>
    <td>Методҳои қадимаи рамзгузорӣ ва барномарезии онҳо дар муҳити Visual Studio </td>
    <td>м.к. Пиров С.М.</td>
  </tr>
  <tr>
    <td>12</td>
    <td>Мирзоев А.</td>
    <td>Истифодаи методҳои муосири рамзгузории иттилоот ва татбиқи онҳо</td>
    <td>м.к. Пиров С.М.</td>
  </tr>
  <tr>
    <td>13</td>
    <td>Ғозиев М.</td>
    <td>Протоколҳои ҳифзи иттилоот ва истифодабарии онҳо</td>
    <td>м.к. Пиров С.М.</td>
  </tr>
  <tr>
    <td>14</td>
    <td>Рабизода  Ш.</td>
    <td>Кибертерроризм падидаи номатлуб</td>
    <td>м.к. Комилов О.</td>
  </tr>
  <tr>
    <td>15</td>
    <td>Сатторов А.</td>
    <td>Кодгузориҳо ва истифодаи васеи онҳо</td>
    <td>м.к. Комилов О.</td>
  </tr>
  <tr>
    <td>16</td>
    <td>Раҳимов Д.</td>
    <td>Роҳҳои ҳифзи иттилоот</td>
    <td>м.к. Комилов О.</td>
  </tr>
  <tr>
    <td>17</td>
    <td>Талбонзода М.</td>
    <td>Коркарди дархостҳо дар интернет-мағоза</td>
    <td>м.к. Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>18</td>
    <td>Қурбонов Э.</td>
    <td>Таҳия ва амалигардонии системаи иттилоотии “Идрор” барои мактаби олӣ</td>
    <td>м.к. Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>19</td>
    <td>Латипов А.</td>
    <td>Забони барномасозии С# ва имкониятҳои он</td>
    <td>асс. Қурбонова Н.</td>
  </tr>
  <tr>
    <td>20</td>
    <td>Имомзода М.</td>
    <td>Ҳеш-функсияҳои криптографӣ</td>
    <td>м.к. Шарифзода З.И.</td>
  </tr>
  <tr>
    <td>21</td>
    <td>Зиёзода Д.</td>
    <td>Маҳдудиятҳо дар SQL</td>
    <td>м.к. Шарифзода З.И.</td>
  </tr>
  <tr>
    <td>22</td>
    <td>Ярова Р.</td>
    <td>Татбиқи усулҳои рамзгузории классикӣ</td>
    <td>м.к. Шарифзода З.И.</td>
  </tr>
  <tr>
    <td>23</td>
    <td>Каримов И.</td>
    <td>Рамзҳои классикии алгебравӣ</td>
    <td>асс. Мусоев С. </td>
  </tr>
  
  <tr className='ttd'>
    <td > </td>
    <td  >Ихтисоси 21050103 курси 3</td>
    <td  > </td>
    <td  > </td>
  </tr>
  <tr className='tras'>
    <td >№</td>
    <td  >Номи насаби донишҷӯ</td>
    <td  >Мавзуъ</td>
    <td  >Роҳбар</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>
  <tr>
    <td>1</td>
    <td>Алиев Алиҷон</td>
    <td>Web-сомонаҳои ватанӣ</td>
    <td>м.к.Ҷумаева Н.Э.</td>
  </tr>

</table>

      </div>
     
       
       </div> 
       </div>
        

     
    )
}

export default Modal;