import React from "react";
import './JS.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink } from 'react-bootstrap';
import { useState } from 'react'
import BE from './../img/BE.png';
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
  FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaJs, FaPhp, FaSquareFull, FaNodeJs, FaCopyright} from 'react-icons/fa';

 

 
  function JS() {
      return (
        <div className="container">
            <div className="js_top">
             
              <div className="ico_title"><h2>JavaScript<FaJs className="js"/></h2> 
              <ul>
                <li>Таҳиягари забон - <b>Брендан Эйх</b>, соли
                   1995 корманди ширкати Netscape, ҳаммуассиси Mozilla, 
                  офаранандаи браузери Firefox ва ҳоло директори генералии Brave Software, Inc.</li>
                <li>Коркарди забон - соли 1995</li>
              </ul>
              </div> 
              <div className="img_title">
                <img 
           src={BE}
           alt="BE"
           width="70px"
           height="80px"
           className="jsimg"
                      /> </div> 
                       </div>
            
       <div className="js_bottom">

 <p>  <b>JavaScript</b> маъмултарин забони барномасозӣ мебошад, ки барои таҳияи web-барномаҳои қисми 
муштарӣ истифода мешавад (дар таҳияи Frontend) ва шаклгузории додаҳо дар он динамикӣ мебошад. JS ҳамчун забони барномасозии бисёрпарадигмавӣ муаррифӣ шудааст, услубҳои ба объект нигаронидашуда,
 императивӣ ва функсионалиро дастгирӣ мекунад.</p>

 <p><b>Китобхонаҳо ва фрейворкҳо:</b> React.js, Vue.js, Ember.js, Adobe Spry,
  AngularJS, Svelte, Dojo, Extjs, jQuery,
  Mootools, Prototype, Qooxdoo, Underscore, React Native, Ionic, Cordova ва PhoneGap</p>
  <p><b>Лоиҳаҳо: </b> </p>
    <ul className="loiha">
      <li>Bun,</li>
      <li>Tauri,</li>
      <li>React,</li>
      <li>Next.js,</li>
      <li>Vite,</li>
      <li>tRPC,</li>
      <li>Astro,</li>
      <li>VS Code,</li>
      <li>Tabby,</li>
      <li>Playwright ..</li>
     

    </ul>
 
<p className="viki"> ВикипедиЯ </p>
       </div>
        

        </div>
   )
}

export default JS;