import React from 'react';
import './Modalkad.css';
function Modalkad({ closeModalkad }){
    return(
       <div className='modalBackgroundkad'> 
       <div className='modalContainerkad'> 
       <div className='titleCloseBtnkad'>
         <button  onClick={() => closeModalkad(false)}>×</button>
       </div>
      
        <p>Саволномаи имтиҳони давлатӣ</p>
       <div className='body'>   
      
       
       <table>
        <tr>
          <td>1.</td><td>Мафҳуми амнияти иттилоот</td>
        </tr>
        <tr>
          <td>2.</td><td>Мафҳуми ядрои системаи омилӣ</td>
        </tr>
        <tr>
          <td>3.</td><td>Системаҳои омилӣ барои таҷҳизотҳои гуногун</td>
        </tr>
        <tr>
          <td>4.</td><td>Ҳифзи мудирӣ ва барномавии иттилоот</td>
        </tr>
        <tr>
          <td>5.</td><td>Мафҳуми рамзгузорӣ</td>
        </tr>
        <tr>
          <td>6.</td><td>Стеганография</td>
        </tr>
        <tr>
          <td>7.</td><td>Биометрия</td>
        </tr>
        <tr>
          <td>8.</td><td>Мафҳуми амнияти иттилоот</td>
        </tr>
        <tr>
          <td>9.</td><td>Мафҳуми ядрои системаи омилӣ</td>
        </tr>
        <tr>
          <td>10.</td><td>Системаҳои омилӣ барои таҷҳизотҳои гуногун</td>
        </tr>
        <tr>
          <td>11.</td><td>Ҳифзи мудирӣ ва барномавии иттилоот</td>
        </tr>
        <tr>
          <td>12.</td><td>Мафҳуми рамзгузорӣ</td>
        </tr>
        <tr>
          <td>13.</td><td>Стеганография</td>
        </tr>
        <tr>
          <td>14.</td><td>Биометрия</td>
        </tr>
       </table>
       </div>
       </div> 
       </div>
        

     
    )
}

export default Modalkad;