import React  from 'react';
import './seminar.css';
 
 
 
/* import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css'; */
 
    import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
        FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
         FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
          FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
          FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
           FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers, FaAtom, FaLongArrowAltRight} from 'react-icons/fa';
                  
     




    function Seminar() {
       

return(
    <div className="seminar">
   
<div className="section_61"> 
<div className="section_6" data-aos="fade-up">
<div className="s1">
<div className="ico6">
<FaChalkboardTeacher className="ChalkboardTeacher6"/></div>
<div className="title6"><a href="#seminar">Семинарҳои кафедра</a></div>
<div className="text6">Дар як моҳ 1 маротиба дар кафедра 
семинари илмӣ доир мегардад. Дар он омӯзгорон фаъолона 
иштирок мекунанд.</div>
</div>
<div className="s2">
<div className="ico6">
<FaCalendarAlt className="ChalkboardTeacher6"/></div>
<div className="title6"><a href="#jn">Ҷадвали навбатдорӣ</a></div>
<div className="text6">Ҳафтае як маротиба барои ҳар як омӯзгор дар кафедра рӯзи навбатдорӣ дар соати муайян таъин гаштааст. </div>
</div>
<div className="s3">
<div className="ico6">
<FaCalendarCheck className="ChalkboardTeacher6"/></div>
<div className="title6"><a href="#jdi">Ҷадвали дарси иловагӣ</a></div>
<div className="text6">Ҳар ҳафта дар вақти муайянгашта омӯзгорон бо донишҷӯён магғулияти иловагӣ ройгон мегузаронанд.</div>
</div>
<div className="s4">
<div className="ico6">
<FaUserTie className="ChalkboardTeacher6"/></div>
<div className="title6"><a href="#sk">Соати кураторӣ</a></div>
<div className="text6">Дар ҳар як курси ихтисоси 98010101-амнияти компютерӣ омӯзгорон ҳамчун сарпараст таъин гаштаанд.</div>
</div>

</div>
</div>
</div>
      
 
    
    )
  }  export default Seminar;