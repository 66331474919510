import React, {useState, useEffect} from 'react';
import './malumot.css';
import OK from './../img/OK.png';
import mudir from './../img/mudir.png';
import NI from './../img/NI.png';
import AH from './../img/AH.png';
import SHZ from './../img/SHZ.png';
import PS from './../img/PS.png';
import KN from './../img/KN.png';
import MS from './../img/MS.png';
import GN from './../img/GN.png';
import KKn from './../img/KKn.png';
import Footer from '../Components/Footer';
import Pagination from '../Components/Pagination';
import Header from '../Components/Header';
import axios from 'axios';
/* import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css'; */
import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk, FaEnvelope, FaBus, FaFacebook, 
    FaWhatsapp, FaInstagram, FaViber, FaGoogle, FaReact, FaPhp, FaSquareFull, FaNodeJs, FaCopyright, FaCheckDouble, FaCheckSquare, FaCheck} from 'react-icons/fa';

  
export const Malumot_7=  () => {
    
    
 

  
   
    const [currentPage, setCurrentPage] = useState(7);
    const totalPages = 10; // Total number of pages
  
 const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        // Perform any other actions based on the page change
      };
  


 /* нужно передать данные на сервер на файл */
 const url = "https://dmttii.tj/api/contacts1.php";
   
 
 
 let fData = new FormData();
 fData.append('str', currentPage);
axios.post(url, fData)
.then(function (response) {
 

})
.catch((error) =>{ // Если запрос не будет выполнен, то ошибка выводится в терминал
alert(error)
})     






 
    
 



      const [t11, setT11] = useState('');
   
   const[data, setData] = useState([]);
  useEffect(()=>{
      // Make a request for a user with a given ID
      axios.get('https://dmttii.tj/api/contacts.php')
        .then((response) => {
         setData(response.data);
      
        })
        .catch((error) => {
          // handle error
          console.log(error);
        })
  }) 

    
        
 
   
 






function openF(){                           // open file   Tezis

    fetch("https://dmttii.tj/api/m_ok.php",{ // куда посилается 
    method : 'POST',  // метод POST 
    header :{
        'Content-Type': 'application/x-www-form-urlencoded',
    },
    body : JSON.stringify({action:1})   // передача параметра ввида строки
    })
  .then(response=> response.text())
  .then(response=>{
   setT11(response);
        
    /*   const arr  = response.split(' '); 
       setT1(arr);
  console.log('arr=',arr);  
      /* setT1(arr.map(person => <li>{person}</li>));*/
     
    })
}
   

    
  
    


return(
    <div className="malumot">
 
    <Header/>
    
        <div className="aitem">
        <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
        
        
            <div className="title">Маълумотнома оид ба омӯзгор</div>
            <div className="grid-aitem">
{data.map((contact, index)=>(
<div key={index}>
    <div className="item-item1">
                <div className="o_name">{contact.names}</div>
                <div className="o_img">  
                <img 
           src={contact.img}
           alt=""
           width="100%"
           height="100%"
                      />
                     
                      
                      </div>
                      <div className="o_p"><p>СУРОҒА</p> {contact.si}</div>
                      <div className="o_p"><p>ТАМОС</p>{contact.tel}</div>
                      <div className="o_p"><p>ПОЧТАИ ЭЛЕКТРОНӢ</p> {contact.email}</div>
                      <div className="o_p"><p>САНАИ ТАВАЛЛУД</p> {contact.st}</div>
             
            </div>
            <div className="item-item2">
                <div className="t_title"><a>ТАҲСИЛОТ</a></div>          
                <div className="t_matn"><b>Хатмкардаи: </b>{contact.hatm}</div>
                <div className="t_matn"><b>Соли дохилшавӣ ва хатм: </b>{contact.ds}</div>
                <div className="t_matn"><b>Аспирантура: </b> {contact.sa}</div>
                <div className="t_matn"><b>Дараҷаи илмӣ: </b>{contact.di}</div>
                <div className="t_matn"><b>Самт:</b> {contact.samt}</div>
                <div className="t_matn"><b>Мавзуи рисола:</b> {contact.mrh}</div>
                 
                 
            </div>
            <div className="item-item3">
            <div className="t_title"><a>ФАЪОЛИЯТИ КОРӢ</a></div>
                <div className="t_matn"><b>Фаъолияти меҳнатӣ: </b>{contact.fm}</div>
                <div className="t_matn"><b>Вазифа: </b>{contact.vaz}</div>
                <div className="t_matn"><b>Собиқаи кории педагогӣ:</b> {contact.sk}</div>
                <div className="t_matn"><b>Аъзои ҳизби:  </b>{contact.ah}</div>
            
               
            </div>
            
            <div className="item-item5">
            <div className="t_title"><a>МУКОФОТҲО</a></div>
            <ul className="t_mukofot">
                    <li> {contact.md}</li>
                     
                </ul>
                <div className="t_title"><a>ПАТЕНТ, ИХТИРОЪ</a></div>
            <ul className="t_mukofot">
            <li>{contact.patent} </li>
                    
                </ul>
                <div className="t_title"><a>САФАРҲОИ ХОРИҶӢ</a></div>
                <ul className="t_mukofot">
                    <li> {contact.sh}</li>
              
                   
                </ul>
                <div className="t_title"><a>ДОНИСТАНИ ЗАБОНҲОИ ХОРИҶӢ</a></div>
                <ul className="t_mukofot">
                    <li>{contact.dzh}</li>
                     
                   
                </ul>
             
                 
            </div>
            <div className="item-item6">
            <div className="t_title"><a>РӮЙХАТИ МАҚОЛАҲО</a>
       <button onClick={openF} class="m_all">Пурра</button> 
               <button  class="m_all">Як соли  охир</button></div>
            <div className="t_text">
          
              <textarea
        name="postContent"
        defaultValue={t11}
        
      />
              
              
                 </div>
            </div>



</div>
))}

 
        
   
           
            
            </div>
            
        </div>
        
        </div>
    
    )
}