import React  from 'react';
import './sfik.css';
 
/* import FontAwesome from 'react-fontawesome';
import faStyles from 'font-awesome/css/font-awesome.css'; */
 
    import {FaPaperPlane, FaMapMarkerAlt, FaPhoneAlt, FaRegClock, FaMailBulk,
        FaEnvelope, FaBus, FaFacebook, FaWhatsapp, FaInstagram, FaViber,
         FaGoogle, FaReact, FaPhp, FaSquareFull, FaFacebookF, FaMusic,
          FaEdit, FaGavel, FaAlignLeft, FaCrown, FaBook, FaBookReader, 
          FaChalkboardTeacher, FaCalendarAlt, FaCalendarCheck, FaCalendarPlus,
           FaUserTie, FaBed, FaRegBell, FaBullhorn, FaChessKing, FaUsers, FaAtom, FaLongArrowAltRight} from 'react-icons/fa';
                  
     




    function SFIK() {
return(
    <div className="sfik">
        
        <div className="oid_kafedra" data-aos="fade">
         
          <div id ="samt" className="samt"  ><a >Самти фаъолияти илмии кафедра</a>
          <p className="msa" ><b>Муассисаи асосӣ:</b> Донишгоҳи миллии Тоҷикистон</p>   
            <p> <b>Кафедра</b> дар факултети механикаю математика </p>
           <p className="p_kafedra"> Кафедраи технологияҳои иттилоотӣ ва иртиботӣ дар татбиқи барномаҳои 
          таълимии бакалаврӣ ва магистрӣ иштирок менамояд. </p>
          <p>   <b>Самт:</b> Технологияи иттилоотӣ, амнияти иттиллоотӣ ва компютерӣ </p>
          <p>   <b id="ihtisos" >Ихтисоси асосӣ:</b> Амнияти компютерӣ - 98010101 </p>
          <p>   <b>Ихтисосҳои муштарак:</b> Забоншиносии компютерӣ - 21050103, Хитоӣ-Информатика - 02030702 </p>
          </div>

         
          <div className="matn">
          
          <p>   Фаъолияти асосии кафедра ин тайёр кардани мутахассисон дар соҳаи коркард 
ва истифодаи воситаҳо ва системаҳои ҳифзи иттилооти компютерӣ.
Донишҷӯёни ин ихтисос забонҳои барномасозӣ, асосоҳои амнияти компютерӣ, усулҳои рамзгузорӣ,
ташкил ва ҳифзи шабакаҳои компютерӣ, воситаҳои техникии ҳифзи иттилоот, алгоритми кодгузорӣ
ва таъмини ҳифзи ҳуқуқи ва барномавии иттилоотро меомӯзанд. 
Хатмкунандагон метавонанд усулҳои муосири ҳифзро 
истифода намояд, ҷараёни ташкили таъминоти барномавиро идора кунанд ва 
ҳифзи системаи компютериро аз вирсуҳо таҳлил намоянд.
Мутахассисони хуби ин соҳа метавонанд, ки дар соҳаҳои гуногун аз қабили 
органҳои ҳокимияти давлативу маҳаллӣ,
корхонаҳои саноатӣ, 
муассисаҳои илмӣ, бонкҳо ва дигар ширкатҳо кору фаъолият намоянд.
             Донишҷӯён аз фанҳои
            тахассусии
            кафедра корҳои курсӣ ва рисолаҳои дипломӣ менависанд ва дар маҳфилҳои кафедра ширкат
            меварзанд. Дар кафедра 10 нафар омӯзгорон фаъолият дошта, аз онҳо як нафар доктори
            илм,
            профессор ва панҷ нафарашон номзади илм мебошанд. Устодони кафедра бо самти технологияи иттилоотӣ, риёзӣ ва амнияти иттилоот пажӯҳишҳои илмӣ мебаранд. Онҳо дар кори семинарҳои илмию методӣ,
            конференсияҳои
            илмии байналхалқӣ, минтақавӣ, ҷумҳуриявӣ ва донишгоҳӣ мунтазам бо маърӯзаю
            гузоришҳои
            илмӣ баромад мекунанд. Дар кафедра семинари илмӣ-методии устодону шогирдон ва
            маҳфилҳои
            илмӣ-таълимии донишҷӯён амал мекунанд, ки дар онҳо устодони соҳибтаҷриба,
            мутахассисони
            ҷавон, аспирантон ва донишҷӯён фаоъолона иштирок меварзанд. Шогирдони пешқадами
            кафедра
            ҳар сол дар олимпиадаҳои фаннии ҷумҳуриявӣ ва озмунҳои илмии донишҷӯён фаъолона иштирок мекунанд.</p>            
             <p> <b>Семинар дар кафедра</b> Семинарҳои кафедра кори худро идома дода истодааст. Ҳар моҳе як маротиба рӯзи
             сешанбе соати 13:00 семинари кафедра баргузор мегардад<a href="#seminar">(ҷадвали семинар)</a>.</p>
            
             <b>Технологияи иттилоотӣ</b>
       
            <p> - равандҳое, ки маҷмуи воситаҳо ва усулҳои ҷамъоварӣ, коркард, захиракунӣ ва 
                  интиқоли маълумотро барои 
                  гирифтани иттилооти нав оид ба ягон объект ва ё ҳолат истифода менамоянд. </p>
                  <p>Ҷузъҳои асосии ТИ:</p>
                  <ul>
                        <li>воситаи таъминоти барномавӣ</li>
                        <li>таъминоти ташкилӣ-методӣ</li>
                        <li>воситаи техникӣ</li>
                  </ul>

                 
             <b>Амнияти иттилоотӣ</b>
           
            
                  <ul>
                        <li><p><b>Ҳифзи иттилоот</b> чорабинии техникии ташкилкардашуда буда, мақсади он пешгирии хатарҳое мебошад, 
                              ки оқибат ба вайроншавии кори системаҳои иттилоотӣ ва компютерӣ,
                               ҳуқуқи шаҳрвандон, ташкилот ё давлат ба мақсади соҳиб шудан ба иттилоот, оварда мерасонад.</p> </li>
                        <li><p><b>Амнияти иттилоот (Information security)</b> – ҳолати ҳифзгаштаи иттилоот
                               бо ёрии воситаҳои техникаи ҳисоббарор ё системаи автоматикунонидашуда аз хатарҳои дохиливу берунӣ/</p></li>
                        
                  </ul>
                  <p>Вобаста ба имконияти вайронкунии 
                        ҳифз дар системаҳои иттилоотӣ ҳама намудҳои ҳифзи 
                        иттилоот ба чунин гурӯҳҳои асосӣ ҷудо мегарданд:</p>
<ul>
      <li>Ҳифзи физикӣ; </li>
      <li>Ҳифзи мудириву барномавӣ;</li>
      <li>Ҳифзи рамзгузорӣ;</li>
      <li>Ҳифзи ҳуқукӣ.</li>
</ul>  
             </div>
        </div> 
        </div>
      
 
    
    )
  }  export default SFIK;