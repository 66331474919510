import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './register.css';
import {AiOutlineSwapRight} from 'react-icons/ai';
import axios from 'axios';
import { NavLink } from 'react-bootstrap';
 


export const Register=()=>{ 
    
    //- useState для хранения наших входных данных
    const [id, setId] = useState('');
    const [names, setNames] = useState('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [st, setSt] = useState('');
    const [si, setSi] = useState('');  
    const [tel, setTel] = useState('');
    const [hatm, setHatm] = useState('');
    const [ds, setDs] = useState('');
    const [sa, setSa] = useState('');
    const [mrh, setMrh] = useState('');
   
    const [di, setDi] = useState('');
    const [ui, setUi] = useState('');
    const [samt, setSamt] = useState('');
    const [vaz, setVaz] = useState('');
    const [ah, setAh] = useState('');
    const [fm, setFm] = useState('');
    const [sk, setSk] = useState('');
    const [md, setMd] = useState('');
    const [dzh, setDzh] = useState('');
    const [sh, setSh] = useState('');    
    const [patent, setPatent] = useState('');
 
 
    // Onclick позволяет нам получить то, что ввел пользователь

    const handleId=(e)=>{
        setId(e.target.value)
        }
    const handleNames=(e)=>{
        setNames(e.target.value)
        }
   
    const handleEmail=(e)=>{
    setEmail(e.target.value)
    }
    
    const handleSt=(e)=>{
        setSt(e.target.value)
        }


    const handlePass=(e)=>{
        setPass(e.target.value)
        }

        const handleSi=(e)=>{
            setSi(e.target.value)
            }
    
        

                const handleTel=(e)=>{
                    setTel(e.target.value)
                    }
                    const handleHatm=(e)=>{
                        setHatm(e.target.value)
                        }
                    const handleDs=(e)=>{
                        setDs(e.target.value)
                        }

                        const handleSa=(e)=>{
                            setSa(e.target.value)
                            }

                            const handleMrh=(e)=>{
                                setMrh(e.target.value)
                                }

                                const handleVaz=(e)=>{
                                    setVaz(e.target.value)
                                    }
                                   
                                            const handleDi=(e)=>{
                                                setDi(e.target.value)
                                                }
                                                const handleUi=(e)=>{
                                                    setUi(e.target.value)
                                                    }
                                                    const handleSamt=(e)=>{
                                                        setSamt(e.target.value)
                                                        }
                                                 
                                                        const handleAh=(e)=>{
                                                            setAh(e.target.value)
                                                            }
                                                            const handleFm=(e)=>{
                                                                setFm(e.target.value)
                                                                }
                                                                const handleSk=(e)=>{
                                                                    setSk(e.target.value)
                                                                    }
                                                                    const handleMd=(e)=>{
                                                                        setMd(e.target.value)
                                                                        }
                                                                        const handleDzh=(e)=>{
                                                                            setDzh(e.target.value)
                                                                            }

                                                                            const handleSh=(e)=>{
                                                                                setSh(e.target.value)
                                                                                }

                                                                           

                                                                                    const handlePatent=(e)=>{
                                                                                        setPatent(e.target.value)
                                                                                        }

    const addUser = ()=>{
        if (id.length===0){
            alert('Майдони id холӣ аст.');
        }
     
        if (email.length===0){
            alert('Майдони email холӣ аст.');
        }
        else if   (pass.length===0){
            alert('Майдони сиркалима холӣ аст.');
        }

        else if   (names.length===0){
            alert('Майдони ном холӣ аст.');
        }
        else if   (st.length===0){
            alert('Майдони санаи таваллуд холӣ аст.');
        }

        else if   (si.length===0){
            alert('Майдони суроға холӣ аст.');
        }        

        else if   (tel.length===0){
            alert('Майдони рақами тел. холӣ аст.');
        }

        else if   (hatm.length===0){
            alert('Майдони номи Донишгоҳ холӣ аст');
        }

        else if   (ds.length===0){
            alert('Майдони соли дохилшавӣ ва хатм холӣ аст');
        }

        else if   (sa.length===0){
            alert('Майдони соли дохилшавӣ ба аспирантура холӣ аст.');
        }

        else if   (mrh.length===0){
            alert('Майдони мавзуи рисолаи номзадӣ холӣ аст.');
        }

      

        else if   (di.length===0){
            alert('Майдони дараҷаи илмӣ холӣ аст.');
        }

        else if   (ui.length===0){
            alert('Майдони унвони илмӣ холӣ аст.');
        }
        else if   (samt.length===0){
            alert('Майдони унвони илмӣ холӣ аст.');
        }
        else if   (vaz.length===0){
            alert('Майдони вазифа холӣ аст.');
        }
       
        
        else if   (ah.length===0){
            alert('Майдони аъзои ҳизб холӣ аст.');
        }
        else if   (fm.length===0){
            alert('Майдони фаъолияти меҳнатӣ холӣ аст.');
        }
        else if   (sk.length===0){
            alert('Майдони собиқаи корӣ холӣ аст.');
        }
        else if   (md.length===0){
            alert('Майдони мукофоти давлатӣ холӣ аст.');
        }
        else if   (dzh.length===0){
            alert('Майдони донистани забони хориҷӣ холӣ аст.');
        }
        else if   (sh.length===0){
            alert('Майдони сафари хориҷӣ холӣ аст.');
        }
      
        else if   (patent.length===0){
            alert('Патент холӣ аст.');
        }
       
         else{

            const url = "https://dmttii.tj/api/reg.php";
            
       let fData = new FormData();
       fData.append('id', id);
       fData.append('names', names);
       fData.append('email', email);
       fData.append('pass', pass);
       fData.append('st', st);
       fData.append('si', si);   
       fData.append('tel', tel);
       fData.append('hatm', hatm);
       fData.append('ds', ds);
       fData.append('sa', sa);
       fData.append('mrh', mrh);
       fData.append('di', di);     
       fData.append('ui', ui);
       fData.append('samt', samt);
       fData.append('vaz', vaz);
       fData.append('ah', ah);
       fData.append('fm', fm);
       fData.append('sk', sk);
       fData.append('md', md);
       fData.append('dzh', dzh);
       fData.append('sh', sh);      
       fData.append('patent', patent);


       axios.post(url, fData)
            .then(function (response) {
           
            console.log(response)
            alert('New Contact Successfully Added.');  
        })
        .catch((error) =>{ // Если запрос не будет выполнен, то ошибка выводится в терминал
            alert(error)
        })  

   




        
                                       }
}
   
   return (  
       <div className='mBackground'> 
        <div className='mContainer'> 
        <div className='titleCloseBtnSignUp'>
        <h5>Бақайдгирӣ</h5>   <button className='bx'> <NavLink   href="/"  > ×</NavLink></button>  
        </div>

        
         
<div className='bg'>
    
<form action="" className='formSignUp'>

 
        
<div className='mb-3'>
        <label htmlFor='names'><strong>Ному насаб </strong></label>
        <input value={names} type='text' id="names" placeholder='Ному насаб пурра' 
        className='form-control rounded-0'onChange={handleNames} />
    </div>
  
    <div className='mb-3'>
        <label htmlFor='email'><strong>Email</strong></label>
        <input  value={email} type='text' id="email" placeholder='Почтаи электронӣ' 
        className='form-control rounded-0'  onChange={handleEmail}  />
    </div>


<div className='mb-3'>
        <label htmlFor='st'><strong>Санаи таваллуд </strong></label>
        <input value={st} type='text' id="st" placeholder='_ _ / _ _ / _ _' 
        className='form-control rounded-0' onChange={handleSt} />
    </div>
    <div className='mb-3'>
        <label htmlFor='si'><strong>Суроғаи истиқомат (пурра) </strong></label>
        <input value={si} type='text' id="si" placeholder='Ноҳия, маҳалла, бино, рақами хона' 
        className='form-control rounded-0'onChange={handleSi} />
    </div>

   
    


    <div className='mb-3'>
        <label htmlFor='tel'><strong>Рақами тел.мобилӣ </strong></label>
        <input value={tel} type='text' id="tel" placeholder='+992 ...' 
        className='form-control rounded-0' onChange={handleTel}/>
    </div>
    
    <div className='mb-3'>
        <label htmlFor='hatm'><strong>Номи донишгоҳ, факултет ва ихтисоси хатмкарда </strong></label>
        <input value={hatm} type='text' id="hatm" placeholder='Номи донишгоҳ, факултет ва ихтисос' 
        className='form-control rounded-0' onChange={handleHatm}/>
    </div>
    <div className='mb-3'>
        <label htmlFor='ds'><strong>Соли дохилшавӣ ва хатм </strong></label>
        <input value={ds} type='text' id="ds" placeholder='Аз кай то кай' 
        className='form-control rounded-0' onChange={handleDs}/>
    </div>

    

    <div className='mb-3'>
        <label htmlFor='sa'><strong>Аспирантураро дар куҷо ва кай хатм кардаед</strong></label>
        <input value={sa}  type='text' id="sa" placeholder='Номи муассиса ва соли хатм ' 
        className='form-control rounded-0' onChange={handleSa}/>
    </div>


    
    <div className='mb-3'>
        <label htmlFor='mrh'><strong>Мавзуи рисолаи номзадӣ  </strong></label>
        <input value={mrh}  type='text' id="mrh" placeholder='Мавзуи рисолаи номзадӣ ' 
        className='form-control rounded-0' onChange={handleMrh} />
    </div>

    


    <div className='mb-3'>
        <label htmlFor='di'><strong>Дараҷаи илмӣ </strong></label>
        <input value={di} type='text' id="di" cplaceholder='Номзади илмҳои...' 
        className='form-control rounded-0'onChange={handleDi}/>
    </div>


  
    <div className='mb-3'>
    <label htmlFor='ui'><strong>Унвони илмӣ </strong></label>
    <input value={ui}  type='text' id="ui" placeholder='Дотсент, профессор' 
    className='form-control rounded-0'onChange={handleUi} />
    </div>

    <div className='mb-3'>
    <label htmlFor='samt'><strong>Самт ва рақами ихтисос</strong></label>
    <input value={samt}  type='text' id="samt" placeholder='Самти тадқиқот, рақами ихтисос' 
    className='form-control rounded-0'onChange={handleSamt} />
    </div>
  



    <div className='mb-3'>
        <label htmlFor='vaz'><strong>Вазифа </strong></label>
        <input value={vaz} type='text' id="vaz" placeholder='Дотсент, профессор' 
        className='form-control rounded-0' onChange={handleVaz}/>
    </div>

    

    <div className='mb-3'>
        <label htmlFor='ah'><strong>Аъзои ҳизб</strong></label>
        <input value={ah} type='text' id="ah" placeholder='Халқӣ-демократӣ, сотсиал-демократӣ, аграрӣ' 
        className='form-control rounded-0' onChange={handleAh}/>
    </div>

    <div className='mb-3'>
        <label htmlFor='fm'><strong>Фаъолияти меҳнатӣ</strong></label>
        <input value={fm} type='text' id="fm"  placeholder='Аз кай то ба кай дар куҷо'
         className='form-control rounded-0'onChange={handleFm}/>
    </div>

    <div className='mb-3'>
        <label htmlFor='sk'><strong>Собиқаи кории педагогӣ</strong></label>
        <input value={sk} type='text' id="sk" placeholder='Чанд сол' 
        className='form-control rounded-0' onChange={handleSk}/>
    </div>

    <div className='mb-3'>
        <label htmlFor='md'><strong>Мукофотҳои давлатӣ</strong></label>
        <input value={md} type='text' id="md" placeholder='Ифтихорнома, сипоснома, аълочии маориф... ' 
        className='form-control rounded-0' onChange={handleMd}/>
    </div>
 <div className='mb-3'>
        <label htmlFor='patent'><strong>Патент, шаҳодатнома ва ё ихтироъот</strong></label>
        <input value={patent} type='text' id="patent" placeholder='Патент ва ихтироъот. ' 
        className='form-control rounded-0' onChange={handlePatent}/>
    </div>

    <div className='mb-3'>
        <label htmlFor='dzh'><strong>Донистани забони хориҷӣ</strong></label>
        <input value={dzh} type='text' id="dzh" placeholder='русӣ, англисӣ, фаронсавӣ..' 
        className='form-control rounded-0' onChange={handleDzh}/>
    </div>

    <div className='mb-3'>
        <label htmlFor='sh'><strong>Сафарҳои хориҷӣ</strong></label>
        <input value={sh} type='text' id="sh" placeholder='Кадом давлат ва шаҳр..' 
        className='form-control rounded-0' onChange={handleSh}/>
    </div>

    <div className='mb-3'>
        <label htmlFor='id'><strong>id-код</strong></label>
        <input value={id} type='password' id="id" placeholder='ID-коди махсус' 
        className='form-control rounded-0' onChange={handleId}/>
    </div>  
    <div className='mb-3'>
        <label htmlFor='pass'><strong>Сиркалима</strong></label>
        <input value={pass} type='password' id="pass" placeholder='Гузоштани сиркалима на кам аз 6 рамз' 
        className='form-control rounded-0' onChange={handlePass}/>
    </div>  



</form>


 </div> 
<div class="bgfooter">
<button   onClick={addUser} className='btn btn-success w-100 rounded-0' >Сабти додаҳо  <AiOutlineSwapRight className='fai'/></button>
<p> </p>
 
        </div>
        
        </div>
</div>
    ) }
 

